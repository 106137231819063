
export const gameDatas = {
  th:[
      { 0: "da***12", 1: "", 2: "ZEUS", 3: "1,258" },
      { 0: "sa***df", 1: "", 2: "Brothers kingdom", 3: "2414" },
      { 0: "ab***690", 1: "", 2: "5 Fortune Dragons", 3: "6,241" },
      { 0: "bfd***lv20", 1: "", 2: "Golden Monkey", 3: "2,045" },
      { 0: "ovb***789", 1: "", 2: "Fafafa", 3: "1,025" },
      { 0: "c7u***2549", 1: "", 2: "Joker's Treasure", 3: "1,325" },
      { 0: "fp***127", 1: "", 2: "Cai Shen 888", 3: "2,025" },
      { 0: "su***hu", 1: "", 2: "Money Mouse", 3: "13,552" },
      { 0: "kka***195", 1: "", 2: "FaFaFa2", 3: "1,655" },
      { 0: "nare***25", 1: "", 2: "Candy Pop", 3: "1,426" },
      { 0: "ke***hasi", 1: "", 2: "Ho Yeah Monkey", 3: "2,999" },
      { 0: "ma***the", 1: "", 2: "Double Flame", 3: "1,745" },
      { 0: "sa***at", 1: "", 2: "Book of Myth", 3: "1,824" },
      { 0: "jin***118", 1: "", 2: "First Love", 3: "4,050" },
      { 0: "ldd***ne11", 1: "", 2: "Dancing Fever", 3: "15,521" },
      { 0: "za***hai", 1: "", 2: "Lucky Koi,", 3: "2,110" },
      { 0: "su***lawe", 1: "", 2: "Mega 7", 3: "5,565" },
      { 0: "pad***aa96", 1: "", 2: "Lucky Cai Shen", 3: "1,265" },
      { 0: "law***gr5", 1: "", 2: "Heroes", 3: "2,252" },
      { 0: "ker***4570", 1: "", 2: "Baby Cai Shen", 3: "6,150" },
      { 0: "dor***tt290", 1: "", 2: "Golden Lotus SE", 3: "1,357" },
      { 0: "bar***4458", 1: "", 2: "First Love", 3: "68,020" },
      { 0: "jclnn***ck39", 1: "", 2: "Mayan Gems", 3: "1,357" },
      { 0: "rite***kl40", 1: "", 2: "Princess Wang", 3: "1,247" },
      { 0: "lan***and", 1: "", 2: "Pineapple Poker", 3: "2,050" },
      { 0: "lold***8", 1: "", 2: "Fiery Sevens", 3: "3,055" },
      { 0: "fh***bt", 1: "", 2: "Dragon Empire", 3: "3,489" },
      { 0: "bck***28", 1: "", 2: "Three Lucky Stars", 3: "6,248" },
      { 0: "sol***lo30", 1: "", 2: "Sweet Bakery", 3: "2,150" },
      { 0: "ang***onc", 1: "", 2: "Magical Lamp", 3: "25,971" },
      { 0: "tave****8525", 1: "", 2: "Gold Panther", 3: "31,260" },
      { 0: "mlv***bg55", 1: "", 2: "Triple Panda", 3: "2,652" },
      { 0: "cb***ty", 1: "", 2: "Mr Chu Tycoon", 3: "652" },
      { 0: "sad**fr", 1: "", 2: "Jungle King", 3: "1,520" },
      { 0: "bar***ke36", 1: "", 2: "168 Fortunes", 3: "2,968" },
      { 0: "tye***b70", 1: "", 2: "Wow Prosperity", 3: "8,460" },
      { 0: "on***889", 1: "", 2: "Golden Chicken", 3: "3,685" },
      { 0: "kati***25id", 1: "", 2: "Gangster Axe", 3: "8,050" },
      { 0: "su9***gn35", 1: "", 2: "Cai Yuan Guang Jin", 3: "588" },
      { 0: "ahde***wat65", 1: "", 2: "Da Fu Xiao Fu", 3: "6,240" },
      { 0: "kkr***tuy8", 1: "", 2: "Pan Fairy", 3: "8,254" },
      { 0: "jaa***gy93", 1: "", 2: "Highway Fortune", 3: "3,208" },
      { 0: "add***od99", 1: "", 2: "Big Prosperity SA", 3: "4,460" },
      { 0: "len***en88", 1: "", 2: "chompoo", 3: "3,654" },
      { 0: "toxh***ve", 1: "", 2: "Tiger Warrior", 3: "2,485" },
      { 0: "rvi***ch16", 1: "", 2: "Double Fortunes", 3: "22,502" },
      { 0: "xd***de26", 1: "", 2: "Golden Whale", 3: "6,145" },
      { 0: "led***can", 1: "", 2: "Alibaba", 3: "365" },
      { 0: "zxcr***bd55", 1: "", 2: "Fist of Gold", 3: "2,065" },
      { 0: "bu***mri20", 1: "", 2: "Emperor Gate SA", 3: "3,051" },
      { 0: "the***cd5", 1: "", 2: "Book of Dead", 3: "2,855" },
      { 0: "bh***658", 1: "", 2: "Mystery Joker", 3: "772" },
      { 0: "pad***fg", 1: "", 2: "Golden Ticket 2", 3: "8,657" },
      { 0: "mi***ta51", 1: "", 2: "Temple of Wealth", 3: "5,251" },
      { 0: "boe***lv66", 1: "", 2: "Celebration of Wealth", 3: "1,362" },
      { 0: "fvc***b12", 1: "", 2: "Fire Joker", 3: "2,598" },
      { 0: "cvr***a4", 1: "", 2: "GEMiX", 3: "2,254" },
      { 0: "new***zo1", 1: "", 2: "Energoonz", 3: "3,189" },
      { 0: "cd***kp31", 1: "", 2: "Big Win Cat", 3: "886" },
      { 0: "ou***vc68", 1: "", 2: "Xmas Joker", 3: "3,489" },
      { 0: "bor***tn22", 1: "", 2: "Goblin Treasure", 3: "22,560" },
      { 0: "joor***kl230", 1: "", 2: "Dirt Bike", 3: "2,668" },
      { 0: "yerd***457", 1: "", 2: "Dog Racing", 3: "22,250" },
     { 0: "bo***2", 1: "", 2: "Goblin Treasure", 3: "10,580" },
      { 0: "jo***dfb30", 1: "", 2: "Dirt Bike", 3: "4,811" },
      { 0: "yesa***30", 1: "", 2: "Dog Racing", 3: "18,250" },
      { 0: "n***46565", 1: "", 2: "asure", 3: "9,250" },
      { 0: "k09***3", 1: "", 2: "Goblin Treasure", 3: "5,957" },
      { 0: "ps32***4", 1: "", 2: "Goblin Treasure", 3: "1,154" },
      { 0: "n***08858", 1: "", 2: "Goblin Treasure", 3: "12,857" },
      { 0: "kk***1", 1: "", 2: "Goblin Treasure", 3: "1,585" },
      { 0: "a***o6", 1: "", 2: "asure", 3: "12,858" },
      { 0: "r***t", 1: "", 2: "Magic Journey", 3: "5,826" },
      { 0: "ni***9", 1: "", 2: "Dog Racing", 3: "3,005" },
      { 0: "vn***80", 1: "", 2: "PinBall Fortune", 3: "52,205" },
      { 0: "tt***21", 1: "", 2: "Dragon Clan", 3: "47,416" },
      { 0: "vn***ee", 1: "", 2: "Dragon Gold SA", 3: "3,050" },
      { 0: "rja***57", 1: "", 2: "Great Stars SA", 3: "954" },
      { 0: "su***0482", 1: "", 2: "Iceland SA", 3: "5,135" },
      { 0: "tyc***21", 1: "", 2: "Lucky Feng Shui", 3: "55,025" },
      { 0: "qw***45", 1: "", 2: "Wong Choy SA", 3: "7,545" },
      { 0: "um***857", 1: "", 2: "5 Fortune SA", 3: "2,542" },
      { 0: "ta***9", 1: "", 2: "King Pharaoh", 3: "4,521" },
      { 0: "su***007", 1: "", 2: "Mermaid", 3: "7,597" },
      { 0: "ta***520", 1: "", 2: "Wong Po", 3: "1,328" },
      { 0: "tbn***519", 1: "", 2: "Adventure Iceland", 3: "5,223" },
      { 0: "t***43", 1: "", 2: "Lucky Baby", 3: "42,540" },
      { 0: "son***87", 1: "", 2: "Great Stars SA", 3: "25,420" },
      { 0: "pat***11", 1: "", 2: "Lucky Strike", 3: "985" },
      { 0: "siri***m", 1: "", 2: "Railway King", 3: "5,921" },
      { 0: "ya***555", 1: "", 2: "Spin Stone", 3: "507" },
      { 0: "tu***888", 1: "", 2: "Wong Choy", 3: "423" },
      { 0: "bc***666", 1: "", 2: "5 Fortune", 3: "8,815" },
      { 0: "bath***44", 1: "", 2: "Master Chef", 3: "6,415" },
      { 0: "ong***man", 1: "", 2: "Moon Princess", 3: "967" },
      { 0: "ok***96", 1: "", 2: "Rise of Olympus", 3: "814" },
      { 0: "ok***20", 1: "", 2: "Tome of Madness", 3: "7,510" },
      { 0: "m***0", 1: "", 2: "Golden Ticket", 3: "985" },
      { 0: "bang***tu", 1: "", 2: "Reactoonz", 3: "6,157" },
      { 0: "m9***ss", 1: "", 2: "BlackJack MH", 3: "4,195" },
      { 0: "omg***v", 1: "", 2: "Reactoonz 2", 3: "9,854" },
      { 0: "fbakd***9", 1: "", 2: "Sweet Alchemy", 3: "351" },
      { 0: "yyyh***sh", 1: "", 2: "Honey Rush", 3: "842" }
    ],
    zh:[
      { 0: "da***12", 1: "", 2: "ZEUS", 3: "1,258" },
      { 0: "sa***df", 1: "", 2: "Brothers kingdom", 3: "2414" },
      { 0: "ab***690", 1: "", 2: "5 Fortune Dragons", 3: "6,241" },
      { 0: "bfd***lv20", 1: "", 2: "Golden Monkey", 3: "2,045" },
      { 0: "ovb***789", 1: "", 2: "Fafafa", 3: "1,025" },
      { 0: "c7u***2549", 1: "", 2: "Joker's Treasure", 3: "1,325" },
      { 0: "fp***127", 1: "", 2: "Cai Shen 888", 3: "2,025" },
      { 0: "su***hu", 1: "", 2: "Money Mouse", 3: "13,552" },
      { 0: "kka***195", 1: "", 2: "FaFaFa2", 3: "1,655" },
      { 0: "nare***25", 1: "", 2: "Candy Pop", 3: "1,426" },
      { 0: "ke***hasi", 1: "", 2: "Ho Yeah Monkey", 3: "2,999" },
      { 0: "ma***the", 1: "", 2: "Double Flame", 3: "1,745" },
      { 0: "sa***at", 1: "", 2: "Book of Myth", 3: "1,824" },
      { 0: "jin***118", 1: "", 2: "First Love", 3: "4,050" },
      { 0: "ldd***ne11", 1: "", 2: "Dancing Fever", 3: "15,521" },
      { 0: "za***hai", 1: "", 2: "Lucky Koi,", 3: "2,110" },
      { 0: "su***lawe", 1: "", 2: "Mega 7", 3: "5,565" },
      { 0: "pad***aa96", 1: "", 2: "Lucky Cai Shen", 3: "1,265" },
      { 0: "law***gr5", 1: "", 2: "Heroes", 3: "2,252" },
      { 0: "ker***4570", 1: "", 2: "Baby Cai Shen", 3: "6,150" },
      { 0: "dor***tt290", 1: "", 2: "Golden Lotus SE", 3: "1,357" },
      { 0: "bar***4458", 1: "", 2: "First Love", 3: "68,020" },
      { 0: "jclnn***ck39", 1: "", 2: "Mayan Gems", 3: "1,357" },
      { 0: "rite***kl40", 1: "", 2: "Princess Wang", 3: "1,247" },
      { 0: "lan***and", 1: "", 2: "Pineapple Poker", 3: "2,050" },
      { 0: "lold***8", 1: "", 2: "Fiery Sevens", 3: "3,055" },
      { 0: "fh***bt", 1: "", 2: "Dragon Empire", 3: "3,489" },
      { 0: "bck***28", 1: "", 2: "Three Lucky Stars", 3: "6,248" },
      { 0: "sol***lo30", 1: "", 2: "Sweet Bakery", 3: "2,150" },
      { 0: "ang***onc", 1: "", 2: "Magical Lamp", 3: "25,971" },
      { 0: "tave****8525", 1: "", 2: "Gold Panther", 3: "31,260" },
      { 0: "mlv***bg55", 1: "", 2: "Triple Panda", 3: "2,652" },
      { 0: "cb***ty", 1: "", 2: "Mr Chu Tycoon", 3: "652" },
      { 0: "sad**fr", 1: "", 2: "Jungle King", 3: "1,520" },
      { 0: "bar***ke36", 1: "", 2: "168 Fortunes", 3: "2,968" },
      { 0: "tye***b70", 1: "", 2: "Wow Prosperity", 3: "8,460" },
      { 0: "on***889", 1: "", 2: "Golden Chicken", 3: "3,685" },
      { 0: "kati***25id", 1: "", 2: "Gangster Axe", 3: "8,050" },
      { 0: "su9***gn35", 1: "", 2: "Cai Yuan Guang Jin", 3: "588" },
      { 0: "ahde***wat65", 1: "", 2: "Da Fu Xiao Fu", 3: "6,240" },
      { 0: "kkr***tuy8", 1: "", 2: "Pan Fairy", 3: "8,254" },
      { 0: "jaa***gy93", 1: "", 2: "Highway Fortune", 3: "3,208" },
      { 0: "add***od99", 1: "", 2: "Big Prosperity SA", 3: "4,460" },
      { 0: "len***en88", 1: "", 2: "chompoo", 3: "3,654" },
      { 0: "toxh***ve", 1: "", 2: "Tiger Warrior", 3: "2,485" },
      { 0: "rvi***ch16", 1: "", 2: "Double Fortunes", 3: "22,502" },
      { 0: "xd***de26", 1: "", 2: "Golden Whale", 3: "6,145" },
      { 0: "led***can", 1: "", 2: "Alibaba", 3: "365" },
      { 0: "zxcr***bd55", 1: "", 2: "Fist of Gold", 3: "2,065" },
      { 0: "bu***mri20", 1: "", 2: "Emperor Gate SA", 3: "3,051" },
      { 0: "the***cd5", 1: "", 2: "Book of Dead", 3: "2,855" },
      { 0: "bh***658", 1: "", 2: "Mystery Joker", 3: "772" },
      { 0: "pad***fg", 1: "", 2: "Golden Ticket 2", 3: "8,657" },
      { 0: "mi***ta51", 1: "", 2: "Temple of Wealth", 3: "5,251" },
      { 0: "boe***lv66", 1: "", 2: "Celebration of Wealth", 3: "1,362" },
      { 0: "fvc***b12", 1: "", 2: "Fire Joker", 3: "2,598" },
      { 0: "cvr***a4", 1: "", 2: "GEMiX", 3: "2,254" },
      { 0: "new***zo1", 1: "", 2: "Energoonz", 3: "3,189" },
      { 0: "cd***kp31", 1: "", 2: "Big Win Cat", 3: "886" },
      { 0: "ou***vc68", 1: "", 2: "Xmas Joker", 3: "3,489" },
      { 0: "bor***tn22", 1: "", 2: "Goblin Treasure", 3: "22,560" },
      { 0: "joor***kl230", 1: "", 2: "Dirt Bike", 3: "2,668" },
      { 0: "yerd***457", 1: "", 2: "Dog Racing", 3: "22,250" },
     { 0: "bo***2", 1: "", 2: "Goblin Treasure", 3: "10,580" },
      { 0: "jo***dfb30", 1: "", 2: "Dirt Bike", 3: "4,811" },
      { 0: "yesa***30", 1: "", 2: "Dog Racing", 3: "18,250" },
      { 0: "n***46565", 1: "", 2: "asure", 3: "9,250" },
      { 0: "k09***3", 1: "", 2: "Goblin Treasure", 3: "5,957" },
      { 0: "ps32***4", 1: "", 2: "Goblin Treasure", 3: "1,154" },
      { 0: "n***08858", 1: "", 2: "Goblin Treasure", 3: "12,857" },
      { 0: "kk***1", 1: "", 2: "Goblin Treasure", 3: "1,585" },
      { 0: "a***o6", 1: "", 2: "asure", 3: "12,858" },
      { 0: "r***t", 1: "", 2: "Magic Journey", 3: "5,826" },
      { 0: "ni***9", 1: "", 2: "Dog Racing", 3: "3,005" },
      { 0: "vn***80", 1: "", 2: "PinBall Fortune", 3: "52,205" },
      { 0: "tt***21", 1: "", 2: "Dragon Clan", 3: "47,416" },
      { 0: "vn***ee", 1: "", 2: "Dragon Gold SA", 3: "3,050" },
      { 0: "rja***57", 1: "", 2: "Great Stars SA", 3: "954" },
      { 0: "su***0482", 1: "", 2: "Iceland SA", 3: "5,135" },
      { 0: "tyc***21", 1: "", 2: "Lucky Feng Shui", 3: "55,025" },
      { 0: "qw***45", 1: "", 2: "Wong Choy SA", 3: "7,545" },
      { 0: "um***857", 1: "", 2: "5 Fortune SA", 3: "2,542" },
      { 0: "ta***9", 1: "", 2: "King Pharaoh", 3: "4,521" },
      { 0: "su***007", 1: "", 2: "Mermaid", 3: "7,597" },
      { 0: "ta***520", 1: "", 2: "Wong Po", 3: "1,328" },
      { 0: "tbn***519", 1: "", 2: "Adventure Iceland", 3: "5,223" },
      { 0: "t***43", 1: "", 2: "Lucky Baby", 3: "42,540" },
      { 0: "son***87", 1: "", 2: "Great Stars SA", 3: "25,420" },
      { 0: "pat***11", 1: "", 2: "Lucky Strike", 3: "985" },
      { 0: "siri***m", 1: "", 2: "Railway King", 3: "5,921" },
      { 0: "ya***555", 1: "", 2: "Spin Stone", 3: "507" },
      { 0: "tu***888", 1: "", 2: "Wong Choy", 3: "423" },
      { 0: "bc***666", 1: "", 2: "5 Fortune", 3: "8,815" },
      { 0: "bath***44", 1: "", 2: "Master Chef", 3: "6,415" },
      { 0: "ong***man", 1: "", 2: "Moon Princess", 3: "967" },
      { 0: "ok***96", 1: "", 2: "Rise of Olympus", 3: "814" },
      { 0: "ok***20", 1: "", 2: "Tome of Madness", 3: "7,510" },
      { 0: "m***0", 1: "", 2: "Golden Ticket", 3: "985" },
      { 0: "bang***tu", 1: "", 2: "Reactoonz", 3: "6,157" },
      { 0: "m9***ss", 1: "", 2: "BlackJack MH", 3: "4,195" },
      { 0: "omg***v", 1: "", 2: "Reactoonz 2", 3: "9,854" },
      { 0: "fbakd***9", 1: "", 2: "Sweet Alchemy", 3: "351" },
      { 0: "yyyh***sh", 1: "", 2: "Honey Rush", 3: "842" }
    ],
    en:[
     { 0: "da***12", 1: "", 2: "ZEUS", 3: "1,258" },
      { 0: "sa***df", 1: "", 2: "Brothers kingdom", 3: "2414" },
      { 0: "ab***690", 1: "", 2: "5 Fortune Dragons", 3: "6,241" },
      { 0: "bfd***lv20", 1: "", 2: "Golden Monkey", 3: "2,045" },
      { 0: "ovb***789", 1: "", 2: "Fafafa", 3: "1,025" },
      { 0: "c7u***2549", 1: "", 2: "Joker's Treasure", 3: "1,325" },
      { 0: "fp***127", 1: "", 2: "Cai Shen 888", 3: "2,025" },
      { 0: "su***hu", 1: "", 2: "Money Mouse", 3: "13,552" },
      { 0: "kka***195", 1: "", 2: "FaFaFa2", 3: "1,655" },
      { 0: "nare***25", 1: "", 2: "Candy Pop", 3: "1,426" },
      { 0: "ke***hasi", 1: "", 2: "Ho Yeah Monkey", 3: "2,999" },
      { 0: "ma***the", 1: "", 2: "Double Flame", 3: "1,745" },
      { 0: "sa***at", 1: "", 2: "Book of Myth", 3: "1,824" },
      { 0: "jin***118", 1: "", 2: "First Love", 3: "4,050" },
      { 0: "ldd***ne11", 1: "", 2: "Dancing Fever", 3: "15,521" },
      { 0: "za***hai", 1: "", 2: "Lucky Koi,", 3: "2,110" },
      { 0: "su***lawe", 1: "", 2: "Mega 7", 3: "5,565" },
      { 0: "pad***aa96", 1: "", 2: "Lucky Cai Shen", 3: "1,265" },
      { 0: "law***gr5", 1: "", 2: "Heroes", 3: "2,252" },
      { 0: "ker***4570", 1: "", 2: "Baby Cai Shen", 3: "6,150" },
      { 0: "dor***tt290", 1: "", 2: "Golden Lotus SE", 3: "1,357" },
      { 0: "bar***4458", 1: "", 2: "First Love", 3: "68,020" },
      { 0: "jclnn***ck39", 1: "", 2: "Mayan Gems", 3: "1,357" },
      { 0: "rite***kl40", 1: "", 2: "Princess Wang", 3: "1,247" },
      { 0: "lan***and", 1: "", 2: "Pineapple Poker", 3: "2,050" },
      { 0: "lold***8", 1: "", 2: "Fiery Sevens", 3: "3,055" },
      { 0: "fh***bt", 1: "", 2: "Dragon Empire", 3: "3,489" },
      { 0: "bck***28", 1: "", 2: "Three Lucky Stars", 3: "6,248" },
      { 0: "sol***lo30", 1: "", 2: "Sweet Bakery", 3: "2,150" },
      { 0: "ang***onc", 1: "", 2: "Magical Lamp", 3: "25,971" },
      { 0: "tave****8525", 1: "", 2: "Gold Panther", 3: "31,260" },
      { 0: "mlv***bg55", 1: "", 2: "Triple Panda", 3: "2,652" },
      { 0: "cb***ty", 1: "", 2: "Mr Chu Tycoon", 3: "652" },
      { 0: "sad**fr", 1: "", 2: "Jungle King", 3: "1,520" },
      { 0: "bar***ke36", 1: "", 2: "168 Fortunes", 3: "2,968" },
      { 0: "tye***b70", 1: "", 2: "Wow Prosperity", 3: "8,460" },
      { 0: "on***889", 1: "", 2: "Golden Chicken", 3: "3,685" },
      { 0: "kati***25id", 1: "", 2: "Gangster Axe", 3: "8,050" },
      { 0: "su9***gn35", 1: "", 2: "Cai Yuan Guang Jin", 3: "588" },
      { 0: "ahde***wat65", 1: "", 2: "Da Fu Xiao Fu", 3: "6,240" },
      { 0: "kkr***tuy8", 1: "", 2: "Pan Fairy", 3: "8,254" },
      { 0: "jaa***gy93", 1: "", 2: "Highway Fortune", 3: "3,208" },
      { 0: "add***od99", 1: "", 2: "Big Prosperity SA", 3: "4,460" },
      { 0: "len***en88", 1: "", 2: "chompoo", 3: "3,654" },
      { 0: "toxh***ve", 1: "", 2: "Tiger Warrior", 3: "2,485" },
      { 0: "rvi***ch16", 1: "", 2: "Double Fortunes", 3: "22,502" },
      { 0: "xd***de26", 1: "", 2: "Golden Whale", 3: "6,145" },
      { 0: "led***can", 1: "", 2: "Alibaba", 3: "365" },
      { 0: "zxcr***bd55", 1: "", 2: "Fist of Gold", 3: "2,065" },
      { 0: "bu***mri20", 1: "", 2: "Emperor Gate SA", 3: "3,051" },
      { 0: "the***cd5", 1: "", 2: "Book of Dead", 3: "2,855" },
      { 0: "bh***658", 1: "", 2: "Mystery Joker", 3: "772" },
      { 0: "pad***fg", 1: "", 2: "Golden Ticket 2", 3: "8,657" },
      { 0: "mi***ta51", 1: "", 2: "Temple of Wealth", 3: "5,251" },
      { 0: "boe***lv66", 1: "", 2: "Celebration of Wealth", 3: "1,362" },
      { 0: "fvc***b12", 1: "", 2: "Fire Joker", 3: "2,598" },
      { 0: "cvr***a4", 1: "", 2: "GEMiX", 3: "2,254" },
      { 0: "new***zo1", 1: "", 2: "Energoonz", 3: "3,189" },
      { 0: "cd***kp31", 1: "", 2: "Big Win Cat", 3: "886" },
      { 0: "ou***vc68", 1: "", 2: "Xmas Joker", 3: "3,489" },
      { 0: "bor***tn22", 1: "", 2: "Goblin Treasure", 3: "22,560" },
      { 0: "joor***kl230", 1: "", 2: "Dirt Bike", 3: "2,668" },
      { 0: "yerd***457", 1: "", 2: "Dog Racing", 3: "22,250" },
      { 0: "bo***2", 1: "", 2: "Goblin Treasure", 3: "10,580" },
      { 0: "jo***dfb30", 1: "", 2: "Dirt Bike", 3: "4,811" },
      { 0: "yesa***30", 1: "", 2: "Dog Racing", 3: "18,250" },
      { 0: "n***46565", 1: "", 2: "asure", 3: "9,250" },
      { 0: "k09***3", 1: "", 2: "Goblin Treasure", 3: "5,957" },
      { 0: "ps32***4", 1: "", 2: "Goblin Treasure", 3: "1,154" },
      { 0: "n***08858", 1: "", 2: "Goblin Treasure", 3: "12,857" },
      { 0: "kk***1", 1: "", 2: "Goblin Treasure", 3: "1,585" },
      { 0: "a***o6", 1: "", 2: "asure", 3: "12,858" },
      { 0: "r***t", 1: "", 2: "Magic Journey", 3: "5,826" },
      { 0: "ni***9", 1: "", 2: "Dog Racing", 3: "3,005" },
      { 0: "vn***80", 1: "", 2: "PinBall Fortune", 3: "52,205" },
      { 0: "tt***21", 1: "", 2: "Dragon Clan", 3: "47,416" },
      { 0: "vn***ee", 1: "", 2: "Dragon Gold SA", 3: "3,050" },
      { 0: "rja***57", 1: "", 2: "Great Stars SA", 3: "954" },
      { 0: "su***0482", 1: "", 2: "Iceland SA", 3: "5,135" },
      { 0: "tyc***21", 1: "", 2: "Lucky Feng Shui", 3: "55,025" },
      { 0: "qw***45", 1: "", 2: "Wong Choy SA", 3: "7,545" },
      { 0: "um***857", 1: "", 2: "5 Fortune SA", 3: "2,542" },
      { 0: "ta***9", 1: "", 2: "King Pharaoh", 3: "4,521" },
      { 0: "su***007", 1: "", 2: "Mermaid", 3: "7,597" },
      { 0: "ta***520", 1: "", 2: "Wong Po", 3: "1,328" },
      { 0: "tbn***519", 1: "", 2: "Adventure Iceland", 3: "5,223" },
      { 0: "t***43", 1: "", 2: "Lucky Baby", 3: "42,540" },
      { 0: "son***87", 1: "", 2: "Great Stars SA", 3: "25,420" },
      { 0: "pat***11", 1: "", 2: "Lucky Strike", 3: "985" },
      { 0: "siri***m", 1: "", 2: "Railway King", 3: "5,921" },
      { 0: "ya***555", 1: "", 2: "Spin Stone", 3: "507" },
      { 0: "tu***888", 1: "", 2: "Wong Choy", 3: "423" },
      { 0: "bc***666", 1: "", 2: "5 Fortune", 3: "8,815" },
      { 0: "bath***44", 1: "", 2: "Master Chef", 3: "6,415" },
      { 0: "ong***man", 1: "", 2: "Moon Princess", 3: "967" },
      { 0: "ok***96", 1: "", 2: "Rise of Olympus", 3: "814" },
      { 0: "ok***20", 1: "", 2: "Tome of Madness", 3: "7,510" },
      { 0: "m***0", 1: "", 2: "Golden Ticket", 3: "985" },
      { 0: "bang***tu", 1: "", 2: "Reactoonz", 3: "6,157" },
      { 0: "m9***ss", 1: "", 2: "BlackJack MH", 3: "4,195" },
      { 0: "omg***v", 1: "", 2: "Reactoonz 2", 3: "9,854" },
      { 0: "fbakd***9", 1: "", 2: "Sweet Alchemy", 3: "351" },
      { 0: "yyyh***sh", 1: "", 2: "Honey Rush", 3: "842" }
    ],
    yn:[
      { 0: "go***8", 1: "", 2: "ZEUS", 3: "1,052" },
      { 0: "al***23", 1: "", 2: "Brothers kingdom", 3: "5,415" },
      { 0: "bo***990", 1: "", 2: "5 Fortune Dragons", 3: "4,141" },
      { 0: "b***i20", 1: "", 2: "Golden Monkey", 3: "1,045" },
      { 0: "ons***789", 1: "", 2: "Fafafa", 3: "4,025" },
      { 0: "u7u***2549", 1: "", 2: "Joker's Treasure", 3: "45,325" },
      { 0: "p***27", 1: "", 2: "Cai Shen 888", 3: "4,025" },
      { 0: "s***hui", 1: "", 2: "Money Mouse", 3: "10,560" },
      { 0: "kk***1995", 1: "", 2: "FaFaFa2", 3: "1,235" },
      { 0: "naru***2534", 1: "", 2: "Candy Pop", 3: "5,426" },
      { 0: "kan***asin", 1: "", 2: "Ho Yeah Monkey", 3: "999" },
      { 0: "m***th", 1: "", 2: "Double Flame", 3: "8,745" },
      { 0: "sat***ath", 1: "", 2: "Book of Myth", 3: "824" },
      { 0: "jne***11", 1: "", 2: "First Love", 3: "1,050" },
      { 0: "lerd***n11", 1: "", 2: "Dancing Fever", 3: "10,520" },
      { 0: "z***uhai", 1: "", 2: "Lucky Koi,", 3: "60,110" },
      { 0: "sulk***law", 1: "", 2: "Mega 7", 3: "6,524" },
      { 0: "par***1896", 1: "", 2: "Lucky Cai Shen", 3: "2,245" },
      { 0: "aw***528", 1: "", 2: "Heroes", 3: "7,542" },
      { 0: "kk***70", 1: "", 2: "Baby Cai Shen", 3: "1,050" },
      { 0: "do***290", 1: "", 2: "Golden Lotus SE", 3: "4,597" },
      { 0: "ba***48", 1: "", 2: "First Love", 3: "5,020" },
      { 0: "jelen***539", 1: "", 2: "Mayan Gems", 3: "5,407" },
      { 0: "krit***jkl40", 1: "", 2: "Princess Wang", 3: "6,547" },
      { 0: "an***a", 1: "", 2: "Pineapple Poker", 3: "4,050" },
      { 0: "lol***8", 1: "", 2: "Fiery Sevens", 3: "1,045" },
      { 0: "ch***at", 1: "", 2: "Dragon Empire", 3: "4,125" },
      { 0: "ble***82128", 1: "", 2: "Three Lucky Stars", 3: "4,251" },
      { 0: "so***30", 1: "", 2: "Sweet Bakery", 3: "1,040" },
      { 0: "a***ong", 1: "", 2: "Magical Lamp", 3: "971" },
      { 0: "kav****25", 1: "", 2: "Gold Panther", 3: "1,050" },
      { 0: "kl***ey55", 1: "", 2: "Triple Panda", 3: "1,052" },
      { 0: "ae***ed", 1: "", 2: "Mr Chu Tycoon", 3: "1,052" },
      { 0: "s***sit", 1: "", 2: "Jungle King", 3: "42,050" },
      { 0: "bo***k25", 1: "", 2: "168 Fortunes", 3: "9,999" },
      { 0: "ye***30", 1: "", 2: "Wow Prosperity", 3: "10,560" },
      { 0: "ons***d789", 1: "", 2: "Golden Chicken", 3: "2,235" },
      { 0: "ka9***gnid", 1: "", 2: "Gangster Axe", 3: "1,050" },
      { 0: "suti***2535", 1: "", 2: "Cai Yuan Guang Jin", 3: "10,560" },
      { 0: "chi***wat", 1: "", 2: "Da Fu Xiao Fu", 3: "5,210" },
      { 0: "de***65", 1: "", 2: "Pan Fairy", 3: "4,254" },
      { 0: "jakkra***0093", 1: "", 2: "Highway Fortune", 3: "4,104" },
      { 0: "aej***99", 1: "", 2: "Big Prosperity SA", 3: "1,050" },
      { 0: "ladd***oden", 1: "", 2: "chompoo", 3: "1,050" },
      { 0: "toey***rvive", 1: "", 2: "Tiger Warrior", 3: "4,452" },
      { 0: "xx***16", 1: "", 2: "Double Fortunes", 3: "10,502" },
      { 0: "ch***26", 1: "", 2: "Golden Whale", 3: "10,105" },
      { 0: "lad***den", 1: "", 2: "Alibaba", 3: "6,652" },
      { 0: "zx***55", 1: "", 2: "Fist of Gold", 3: "1,050" },
      { 0: "bu***i20", 1: "", 2: "Emperor Gate SA", 3: "1,052" },
      { 0: "mr***5", 1: "", 2: "Book of Dead", 3: "1,055" },
      { 0: "the***31", 1: "", 2: "Mystery Joker", 3: "8,854" },
      { 0: "pp***986", 1: "", 2: "Golden Ticket 2", 3: "10,501" },
      { 0: "tnmi***51", 1: "", 2: "Temple of Wealth", 3: "251" },
      { 0: "love***64", 1: "", 2: "Celebration of Wealth", 3: "4,452" },
      { 0: "advc***h", 1: "", 2: "Fire Joker", 3: "8,521" },
      { 0: "pol***4", 1: "", 2: "GEMiX", 3: "45,254" },
      { 0: "zonr***n1", 1: "", 2: "Energoonz", 3: "4,272" },
      { 0: "opl***24", 1: "", 2: "Big Win Cat", 3: "8,247" },
      { 0: "ton***68", 1: "", 2: "Xmas Joker", 3: "14,529" },
      { 0: "bo***23722", 1: "", 2: "Goblin Treasure", 3: "10,560" },
      { 0: "jo***230", 1: "", 2: "Dirt Bike", 3: "5,811" },
      { 0: "ye***30", 1: "", 2: "Dog Racing", 3: "10,250" },
      { 0: "nn***42", 1: "", 2: "asure", 3: "10,250" },
      { 0: "kk09***5183", 1: "", 2: "Goblin Treasure", 3: "5,011" },
      { 0: "ps***4", 1: "", 2: "Goblin Treasure", 3: "1,050" },
      { 0: "n***08", 1: "", 2: "Goblin Treasure", 3: "12,524" },
      { 0: "kak***01", 1: "", 2: "Goblin Treasure", 3: "1,055" },
      { 0: "a***8046", 1: "", 2: "asure", 3: "12,025" },
      { 0: "ra***at", 1: "", 2: "Magic Journey", 3: "5,426" },
      { 0: "nic***993", 1: "", 2: "Dog Racing", 3: "1,005" },
      { 0: "ven***90", 1: "", 2: "PinBall Fortune", 3: "1,205" },
      { 0: "tant***21", 1: "", 2: "Dragon Clan", 3: "5,416" },
      { 0: "van***eree", 1: "", 2: "Dragon Gold SA", 3: "1,050" },
      { 0: "r***57", 1: "", 2: "Great Stars SA", 3: "854" },
      { 0: "sug***987", 1: "", 2: "Iceland SA", 3: "1,535" },
      { 0: "tayj***20", 1: "", 2: "Lucky Feng Shui", 3: "75,025" },
      { 0: "q1w***4t5", 1: "", 2: "Wong Choy SA", 3: "2,245" },
      { 0: "glum***980", 1: "", 2: "5 Fortune SA", 3: "7,542" },
      { 0: "ta***9", 1: "", 2: "King Pharaoh", 3: "5,521" },
      { 0: "po***07", 1: "", 2: "Mermaid", 3: "4,597" },
      { 0: "teera***n2520", 1: "", 2: "Wong Po", 3: "5,479" },
      { 0: "bun***37", 1: "", 2: "Adventure Iceland", 3: "1,023" },
      { 0: "thi***43", 1: "", 2: "Lucky Baby", 3: "42,540" },
      { 0: "son***am87", 1: "", 2: "Great Stars SA", 3: "15,420" },
      { 0: "pat***11", 1: "", 2: "Lucky Strike", 3: "985" },
      { 0: "ro***ma", 1: "", 2: "Railway King", 3: "5,421" },
      { 0: "ya***y27", 1: "", 2: "Spin Stone", 3: "647" },
      { 0: "tu***35", 1: "", 2: "Wong Choy", 3: "887" },
      { 0: "ben***526", 1: "", 2: "5 Fortune", 3: "4,254" },
      { 0: "taw***0444", 1: "", 2: "Master Chef", 3: "1,495" },
      { 0: "ong***man", 1: "", 2: "Moon Princess", 3: "967" },
      { 0: "par***96", 1: "", 2: "Rise of Olympus", 3: "424" },
      { 0: "ve***290", 1: "", 2: "Tome of Madness", 3: "1,050" },
      { 0: "ar***80", 1: "", 2: "Golden Ticket", 3: "665" },
      { 0: "ban***57", 1: "", 2: "Reactoonz", 3: "4,457" },
      { 0: "m9***82", 1: "", 2: "BlackJack MH", 3: "2,235" },
      { 0: "tw***ve", 1: "", 2: "Reactoonz 2", 3: "7,954" },
      { 0: "fdal***97", 1: "", 2: "Sweet Alchemy", 3: "457" },
      { 0: "you***6", 1: "", 2: "Honey Rush", 3: "636" }
    ],
    id:[
      { 0: "go***8", 1: "", 2: "ZEUS", 3: "1,052" },
      { 0: "al***23", 1: "", 2: "Brothers kingdom", 3: "5,415" },
      { 0: "bo***990", 1: "", 2: "5 Fortune Dragons", 3: "4,141" },
      { 0: "b***i20", 1: "", 2: "Golden Monkey", 3: "1,045" },
      { 0: "ons***789", 1: "", 2: "Fafafa", 3: "4,025" },
      { 0: "u7u***2549", 1: "", 2: "Joker's Treasure", 3: "45,325" },
      { 0: "p***27", 1: "", 2: "Cai Shen 888", 3: "4,025" },
      { 0: "s***hui", 1: "", 2: "Money Mouse", 3: "10,560" },
      { 0: "kk***1995", 1: "", 2: "FaFaFa2", 3: "1,235" },
      { 0: "naru***2534", 1: "", 2: "Candy Pop", 3: "5,426" },
      { 0: "kan***asin", 1: "", 2: "Ho Yeah Monkey", 3: "999" },
      { 0: "m***th", 1: "", 2: "Double Flame", 3: "8,745" },
      { 0: "sat***ath", 1: "", 2: "Book of Myth", 3: "824" },
      { 0: "jne***11", 1: "", 2: "First Love", 3: "1,050" },
      { 0: "lerd***n11", 1: "", 2: "Dancing Fever", 3: "10,520" },
      { 0: "z***uhai", 1: "", 2: "Lucky Koi,", 3: "60,110" },
      { 0: "sulk***law", 1: "", 2: "Mega 7", 3: "6,524" },
      { 0: "par***1896", 1: "", 2: "Lucky Cai Shen", 3: "2,245" },
      { 0: "aw***528", 1: "", 2: "Heroes", 3: "7,542" },
      { 0: "kk***70", 1: "", 2: "Baby Cai Shen", 3: "1,050" },
      { 0: "do***290", 1: "", 2: "Golden Lotus SE", 3: "4,597" },
      { 0: "ba***48", 1: "", 2: "First Love", 3: "5,020" },
      { 0: "jelen***539", 1: "", 2: "Mayan Gems", 3: "5,407" },
      { 0: "krit***jkl40", 1: "", 2: "Princess Wang", 3: "6,547" },
      { 0: "an***a", 1: "", 2: "Pineapple Poker", 3: "4,050" },
      { 0: "lol***8", 1: "", 2: "Fiery Sevens", 3: "1,045" },
      { 0: "ch***at", 1: "", 2: "Dragon Empire", 3: "4,125" },
      { 0: "ble***82128", 1: "", 2: "Three Lucky Stars", 3: "4,251" },
      { 0: "so***30", 1: "", 2: "Sweet Bakery", 3: "1,040" },
      { 0: "a***ong", 1: "", 2: "Magical Lamp", 3: "971" },
      { 0: "kav****25", 1: "", 2: "Gold Panther", 3: "1,050" },
      { 0: "kl***ey55", 1: "", 2: "Triple Panda", 3: "1,052" },
      { 0: "ae***ed", 1: "", 2: "Mr Chu Tycoon", 3: "1,052" },
      { 0: "s***sit", 1: "", 2: "Jungle King", 3: "42,050" },
      { 0: "bo***k25", 1: "", 2: "168 Fortunes", 3: "9,999" },
      { 0: "ye***30", 1: "", 2: "Wow Prosperity", 3: "10,560" },
      { 0: "ons***d789", 1: "", 2: "Golden Chicken", 3: "2,235" },
      { 0: "ka9***gnid", 1: "", 2: "Gangster Axe", 3: "1,050" },
      { 0: "suti***2535", 1: "", 2: "Cai Yuan Guang Jin", 3: "10,560" },
      { 0: "chi***wat", 1: "", 2: "Da Fu Xiao Fu", 3: "5,210" },
      { 0: "de***65", 1: "", 2: "Pan Fairy", 3: "4,254" },
      { 0: "jakkra***0093", 1: "", 2: "Highway Fortune", 3: "4,104" },
      { 0: "aej***99", 1: "", 2: "Big Prosperity SA", 3: "1,050" },
      { 0: "ladd***oden", 1: "", 2: "chompoo", 3: "1,050" },
      { 0: "toey***rvive", 1: "", 2: "Tiger Warrior", 3: "4,452" },
      { 0: "xx***16", 1: "", 2: "Double Fortunes", 3: "10,502" },
      { 0: "ch***26", 1: "", 2: "Golden Whale", 3: "10,105" },
      { 0: "lad***den", 1: "", 2: "Alibaba", 3: "6,652" },
      { 0: "zx***55", 1: "", 2: "Fist of Gold", 3: "1,050" },
      { 0: "bu***i20", 1: "", 2: "Emperor Gate SA", 3: "1,052" },
      { 0: "mr***5", 1: "", 2: "Book of Dead", 3: "1,055" },
      { 0: "the***31", 1: "", 2: "Mystery Joker", 3: "8,854" },
      { 0: "pp***986", 1: "", 2: "Golden Ticket 2", 3: "10,501" },
      { 0: "tnmi***51", 1: "", 2: "Temple of Wealth", 3: "251" },
      { 0: "love***64", 1: "", 2: "Celebration of Wealth", 3: "4,452" },
      { 0: "advc***h", 1: "", 2: "Fire Joker", 3: "8,521" },
      { 0: "pol***4", 1: "", 2: "GEMiX", 3: "45,254" },
      { 0: "zonr***n1", 1: "", 2: "Energoonz", 3: "4,272" },
      { 0: "opl***24", 1: "", 2: "Big Win Cat", 3: "8,247" },
      { 0: "ton***68", 1: "", 2: "Xmas Joker", 3: "14,529" },
      { 0: "bo***23722", 1: "", 2: "Goblin Treasure", 3: "10,560" },
      { 0: "jo***230", 1: "", 2: "Dirt Bike", 3: "5,811" },
      { 0: "ye***30", 1: "", 2: "Dog Racing", 3: "10,250" },
      { 0: "nn***42", 1: "", 2: "asure", 3: "10,250" },
      { 0: "kk09***5183", 1: "", 2: "Goblin Treasure", 3: "5,011" },
      { 0: "ps***4", 1: "", 2: "Goblin Treasure", 3: "1,050" },
      { 0: "n***08", 1: "", 2: "Goblin Treasure", 3: "12,524" },
      { 0: "kak***01", 1: "", 2: "Goblin Treasure", 3: "1,055" },
      { 0: "a***8046", 1: "", 2: "asure", 3: "12,025" },
      { 0: "ra***at", 1: "", 2: "Magic Journey", 3: "5,426" },
      { 0: "nic***993", 1: "", 2: "Dog Racing", 3: "1,005" },
      { 0: "ven***90", 1: "", 2: "PinBall Fortune", 3: "1,205" },
      { 0: "tant***21", 1: "", 2: "Dragon Clan", 3: "5,416" },
      { 0: "van***eree", 1: "", 2: "Dragon Gold SA", 3: "1,050" },
      { 0: "r***57", 1: "", 2: "Great Stars SA", 3: "854" },
      { 0: "sug***987", 1: "", 2: "Iceland SA", 3: "1,535" },
      { 0: "tayj***20", 1: "", 2: "Lucky Feng Shui", 3: "75,025" },
      { 0: "q1w***4t5", 1: "", 2: "Wong Choy SA", 3: "2,245" },
      { 0: "glum***980", 1: "", 2: "5 Fortune SA", 3: "7,542" },
      { 0: "ta***9", 1: "", 2: "King Pharaoh", 3: "5,521" },
      { 0: "po***07", 1: "", 2: "Mermaid", 3: "4,597" },
      { 0: "teera***n2520", 1: "", 2: "Wong Po", 3: "5,479" },
      { 0: "bun***37", 1: "", 2: "Adventure Iceland", 3: "1,023" },
      { 0: "thi***43", 1: "", 2: "Lucky Baby", 3: "42,540" },
      { 0: "son***am87", 1: "", 2: "Great Stars SA", 3: "15,420" },
      { 0: "pat***11", 1: "", 2: "Lucky Strike", 3: "985" },
      { 0: "ro***ma", 1: "", 2: "Railway King", 3: "5,421" },
      { 0: "ya***y27", 1: "", 2: "Spin Stone", 3: "647" },
      { 0: "tu***35", 1: "", 2: "Wong Choy", 3: "887" },
      { 0: "ben***526", 1: "", 2: "5 Fortune", 3: "4,254" },
      { 0: "taw***0444", 1: "", 2: "Master Chef", 3: "1,495" },
      { 0: "ong***man", 1: "", 2: "Moon Princess", 3: "967" },
      { 0: "par***96", 1: "", 2: "Rise of Olympus", 3: "424" },
      { 0: "ve***290", 1: "", 2: "Tome of Madness", 3: "1,050" },
      { 0: "ar***80", 1: "", 2: "Golden Ticket", 3: "665" },
      { 0: "ban***57", 1: "", 2: "Reactoonz", 3: "4,457" },
      { 0: "m9***82", 1: "", 2: "BlackJack MH", 3: "2,235" },
      { 0: "tw***ve", 1: "", 2: "Reactoonz 2", 3: "7,954" },
      { 0: "fdal***97", 1: "", 2: "Sweet Alchemy", 3: "457" },
      { 0: "you***6", 1: "", 2: "Honey Rush", 3: "636" }
    ],
}

export const menberDatas = {
  th:[
      { 0: "m**ke", 1: "", 2: "โบนัสอัดเกรค", 3: "38" },
      { 0: "luc****yy", 1: "", 2: "โบนัสอัดเกรค", 3: "888" },
      { 0: "jne***233", 1: "", 2: "โบนัสนำโชค", 3: "188" },
      { 0: "no***rio", 1: "", 2: "โบนัสนำโชค", 3: "188" },
      { 0: "pa***mee", 1: "", 2: "โบนัสนำโชค", 3: "188" },
      { 0: "cre***309", 1: "", 2: "โบนัสนำโชค", 3: "258" },
      { 0: "me***k89", 1: "", 2: "โบนัสนำโชค", 3: "288" },
      { 0: "dun****t27", 1: "", 2: "โบนัสวันเกิด", 3: "120" },
      { 0: "ma**le", 1: "", 2: "โบนัสวันเกิด", 3: "120" },
      { 0: "xia***ng", 1: "", 2: "โบนัสวันเกิด", 3: "60" },
      { 0: "apil***527", 1: "", 2: "โบนัสวันเกิด", 3: "30" },
      { 0: "zx***55", 1: "", 2: "โบนัสอัดเกรค", 3: "11188" },
      { 0: "al***23", 1: "", 2: "โบนัสวันเกิด", 3: "400" },
      { 0: "naru***2534", 1: "", 2: "โบนัสอัดเกรค", 3: "588" },
      { 0: "toy***99", 1: "", 2: "โบนัสนำโชค", 3: "1888" },
      { 0: "kan***asin", 1: "", 2: "โบนัสนำโชค", 3: "688" },
      { 0: "m***th", 1: "", 2: "โบนัสวันเกิด", 3: "1560" },
      { 0: "par***1896", 1: "", 2: "โบนัสนำโชค", 3: "788" },
      { 0: "z***uhai", 1: "", 2: "โบนัสยอดเสีย", 3: "71.2" },
      { 0: "sat***ath", 1: "", 2: "โบนัสยอดเสีย", 3: "120" },
      { 0: "jne***11", 1: "", 2: "โบนัสอัดเกรค", 3: "188" },
      { 0: "sulk***law", 1: "", 2: "โบนัสวันเกิด", 3: "560" },
      { 0: "aw***528", 1: "", 2: "โบนัสยอดเสีย", 3: "3874.8" },
      { 0: "kk***70", 1: "", 2: "โบนัสอัดเกรค", 3: "88" },
      { 0: "jelen***539", 1: "", 2: "โบนัสนำโชค", 3: "588" },
      { 0: "krit***jkl40", 1: "", 2: "โบนัสยอดเสีย", 3: "12.6" },
      { 0: "ba***48", 1: "", 2: "โบนัสนำโชค", 3: "458" },
      { 0: "do***290", 1: "", 2: "โบนัสอัดเกรค", 3: "88" },
    ],
    zh:[
      { 0: "m**ke", 1: "", 2: "晋级彩金", 3: "38" },
      { 0: "luc****yy", 1: "", 2: "晋级彩金", 3: "888" },
      { 0: "jne***233", 1: "", 2: "月月好运彩金", 3: "188" },
      { 0: "no***rio", 1: "", 2: "月月好运彩金", 3: "188" },
      { 0: "pa***mee", 1: "", 2: "月月好运彩金", 3: "188" },
      { 0: "cre***309", 1: "", 2: "月月好运彩金", 3: "258" },
      { 0: "me***k89", 1: "", 2: "月月好运彩金", 3: "288" },
      { 0: "dun****t27", 1: "", 2: "生日礼金", 3: "120" },
      { 0: "ma**le", 1: "", 2: "生日礼金", 3: "120" },
      { 0: "xia***ng", 1: "", 2: "生日礼金", 3: "60" },
      { 0: "apil***527", 1: "", 2: "生日礼金", 3: "30" },
      { 0: "zx***55", 1: "", 2: "晋级彩金", 3: "11188" },
      { 0: "al***23", 1: "", 2: "生日礼金", 3: "400" },
      { 0: "naru***2534", 1: "", 2: "晋级彩金", 3: "588" },
      { 0: "toy***99", 1: "", 2: "月月好运彩金", 3: "1888" },
      { 0: "kan***asin", 1: "", 2: "月月好运彩金", 3: "688" },
      { 0: "m***th", 1: "", 2: "生日礼金", 3: "1560" },
      { 0: "par***1896", 1: "", 2: "月月好运彩金", 3: "788" },
      { 0: "z***uhai", 1: "", 2: "负利彩金", 3: "71.2" },
      { 0: "sat***ath", 1: "", 2: "负利彩金", 3: "120" },
      { 0: "jne***11", 1: "", 2: "晋级彩金", 3: "188" },
      { 0: "sulk***law", 1: "", 2: "生日礼金", 3: "560" },
      { 0: "aw***528", 1: "", 2: "负利彩金", 3: "3874.8" },
      { 0: "kk***70", 1: "", 2: "晋级彩金", 3: "88" },
      { 0: "jelen***539", 1: "", 2: "月月好运彩金", 3: "588" },
      { 0: "krit***jkl40", 1: "", 2: "负利彩金", 3: "12.6" },
      { 0: "ba***48", 1: "", 2: "月月好运彩金", 3: "458" },
      { 0: "do***290", 1: "", 2: "晋级彩金", 3: "88" },
    ],
    en:[
      { 0: "m**ke", 1: "", 2: "Advance Jackpot", 3: "38" },
      { 0: "luc****yy", 1: "", 2: "Advance Jackpot", 3: "888" },
      { 0: "jne***233", 1: "", 2: "Good Luck Jackpot", 3: "188" },
      { 0: "no***rio", 1: "", 2: "Good Luck Jackpot", 3: "188" },
      { 0: "pa***mee", 1: "", 2: "Good Luck Jackpot", 3: "188" },
      { 0: "cre***309", 1: "", 2: "Good Luck Jackpot", 3: "258" },
      { 0: "me***k89", 1: "", 2: "Good Luck Jackpot", 3: "288" },
      { 0: "dun****t27", 1: "", 2: "Birthday gift", 3: "120" },
      { 0: "ma**le", 1: "", 2: "Birthday gift", 3: "120" },
      { 0: "xia***ng", 1: "", 2: "Birthday gift", 3: "60" },
      { 0: "apil***527", 1: "", 2: "Birthday gift", 3: "30" },
      { 0: "zx***55", 1: "", 2: "Advance Jackpot", 3: "11188" },
      { 0: "al***23", 1: "", 2: "Birthday gift", 3: "400" },
      { 0: "naru***2534", 1: "", 2: "Advance Jackpot", 3: "588" },
      { 0: "toy***99", 1: "", 2: "Good Luck Jackpot", 3: "1888" },
      { 0: "kan***asin", 1: "", 2: "Good Luck Jackpot", 3: "688" },
      { 0: "m***th", 1: "", 2: "Birthday gift", 3: "1560" },
      { 0: "par***1896", 1: "", 2: "Good Luck Jackpot", 3: "788" },
      { 0: "z***uhai", 1: "", 2: "Negative winnings", 3: "71.2" },
      { 0: "sat***ath", 1: "", 2: "Negative winnings", 3: "120" },
      { 0: "jne***11", 1: "", 2: "Advance Jackpot", 3: "188" },
      { 0: "sulk***law", 1: "", 2: "Birthday gift", 3: "560" },
      { 0: "aw***528", 1: "", 2: "Negative winnings", 3: "3874.8" },
      { 0: "kk***70", 1: "", 2: "Advance Jackpot", 3: "88" },
      { 0: "jelen***539", 1: "", 2: "Good Luck Jackpot", 3: "588" },
      { 0: "krit***jkl40", 1: "", 2: "Negative winnings", 3: "12.6" },
      { 0: "ba***48", 1: "", 2: "Good Luck Jackpot", 3: "458" },
      { 0: "do***290", 1: "", 2: "Advance Jackpot", 3: "88" },
    ],
    yn:[
      { 0: "m**ke", 1: "", 2: "Phần thưởng khuyến mại", 3: "38" },
      { 0: "luc****yy", 1: "", 2: "Phần thưởng khuyến mại", 3: "888" },
      { 0: "jne***233", 1: "", 2: "Phần thưởng may mắn hàng tháng", 3: "188" },
      { 0: "no***rio", 1: "", 2: "Phần thưởng may mắn hàng tháng", 3: "188" },
      { 0: "pa***mee", 1: "", 2: "Phần thưởng may mắn hàng tháng", 3: "188" },
      { 0: "cre***309", 1: "", 2: "Phần thưởng may mắn hàng tháng", 3: "258" },
      { 0: "me***k89", 1: "", 2: "Phần thưởng may mắn hàng tháng", 3: "288" },
      { 0: "dun****t27", 1: "", 2: "Phần thưởng sinh nhật", 3: "120" },
      { 0: "ma**le", 1: "", 2: "Phần thưởng sinh nhật", 3: "120" },
      { 0: "xia***ng", 1: "", 2: "Phần thưởng sinh nhật", 3: "60" },
      { 0: "apil***527", 1: "", 2: "Phần thưởng sinh nhật", 3: "30" },
      { 0: "zx***55", 1: "", 2: "Phần thưởng khuyến mại", 3: "11188" },
      { 0: "al***23", 1: "", 2: "Phần thưởng sinh nhật", 3: "400" },
      { 0: "naru***2534", 1: "", 2: "Phần thưởng khuyến mại", 3: "588" },
      { 0: "toy***99", 1: "", 2: "Phần thưởng may mắn hàng tháng", 3: "1888" },
      { 0: "kan***asin", 1: "", 2: "Phần thưởng may mắn hàng tháng", 3: "688" },
      { 0: "m***th", 1: "", 2: "Phần thưởng sinh nhật", 3: "1560" },
      { 0: "par***1896", 1: "", 2: "Phần thưởng may mắn hàng tháng", 3: "788" },
      { 0: "z***uhai", 1: "", 2: "Phần thưởng lợi nhuận âm", 3: "71.2" },
      { 0: "sat***ath", 1: "", 2: "Phần thưởng lợi nhuận âm", 3: "120" },
      { 0: "jne***11", 1: "", 2: "Phần thưởng khuyến mại", 3: "188" },
      { 0: "sulk***law", 1: "", 2: "Phần thưởng sinh nhật", 3: "560" },
      { 0: "aw***528", 1: "", 2: "Phần thưởng lợi nhuận âm", 3: "3874.8" },
      { 0: "kk***70", 1: "", 2: "Phần thưởng khuyến mại", 3: "88" },
      { 0: "jelen***539", 1: "", 2: "Phần thưởng may mắn hàng tháng", 3: "588" },
      { 0: "krit***jkl40", 1: "", 2: "Phần thưởng lợi nhuận âm", 3: "12.6" },
      { 0: "ba***48", 1: "", 2: "Phần thưởng may mắn hàng tháng", 3: "458" },
      { 0: "do***290", 1: "", 2: "Phần thưởng khuyến mại", 3: "88" },
    ],
    id:[
      { 0: "m**ke", 1: "", 2: "Hadiah promosi", 3: "38" },
      { 0: "luc****yy", 1: "", 2: "Hadiah promosi", 3: "888" },
      { 0: "jne***233", 1: "", 2: "Hadiah Keberuntungan Bulanan", 3: "188" },
      { 0: "no***rio", 1: "", 2: "Hadiah Keberuntungan Bulanan", 3: "188" },
      { 0: "pa***mee", 1: "", 2: "Hadiah Keberuntungan Bulanan", 3: "188" },
      { 0: "cre***309", 1: "", 2: "Hadiah Keberuntungan Bulanan", 3: "258" },
      { 0: "me***k89", 1: "", 2: "Hadiah Keberuntungan Bulanan", 3: "288" },
      { 0: "dun****t27", 1: "", 2: "Bonus ulang tahun", 3: "120" },
      { 0: "ma**le", 1: "", 2: "Bonus ulang tahun", 3: "120" },
      { 0: "xia***ng", 1: "", 2: "Bonus ulang tahun", 3: "60" },
      { 0: "apil***527", 1: "", 2: "Bonus ulang tahun", 3: "30" },
      { 0: "zx***55", 1: "", 2: "Hadiah promosi", 3: "11188" },
      { 0: "al***23", 1: "", 2: "Bonus ulang tahun", 3: "400" },
      { 0: "naru***2534", 1: "", 2: "Hadiah promosi", 3: "588" },
      { 0: "toy***99", 1: "", 2: "Hadiah Keberuntungan Bulanan", 3: "1888" },
      { 0: "kan***asin", 1: "", 2: "Hadiah Keberuntungan Bulanan", 3: "688" },
      { 0: "m***th", 1: "", 2: "Bonus ulang tahun", 3: "1560" },
      { 0: "par***1896", 1: "", 2: "Hadiah Keberuntungan Bulanan", 3: "788" },
      { 0: "z***uhai", 1: "", 2: "Bonus keuntungan negatif", 3: "71.2" },
      { 0: "sat***ath", 1: "", 2: "Bonus keuntungan negatif", 3: "120" },
      { 0: "jne***11", 1: "", 2: "Hadiah promosi", 3: "188" },
      { 0: "sulk***law", 1: "", 2: "Bonus ulang tahun", 3: "560" },
      { 0: "aw***528", 1: "", 2: "Bonus keuntungan negatif", 3: "3874.8" },
      { 0: "kk***70", 1: "", 2: "Hadiah promosi", 3: "88" },
      { 0: "jelen***539", 1: "", 2: "Hadiah Keberuntungan Bulanan", 3: "588" },
      { 0: "krit***jkl40", 1: "", 2: "Bonus keuntungan negatif", 3: "12.6" },
      { 0: "ba***48", 1: "", 2: "Hadiah Keberuntungan Bulanan", 3: "458" },
      { 0: "do***290", 1: "", 2: "Hadiah promosi", 3: "88" },
    ],
}
